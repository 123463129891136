import {Box, Image, Center, chakra, Heading, Text, VStack, Input, HStack, Button, Checkbox} from "@chakra-ui/react";
import { isMobile } from 'react-device-detect'
import Home from "./pages/Home";

function App() {
    return (
        <Home/>
    );
}

export default App;

import {Box} from "@chakra-ui/react";
import {LogoHeader} from "../components/home-contents/LogoHeader";
import {Description} from "../components/home-contents/Description";
import {Mission} from "../components/home-contents/Mission";
import {SignupForm} from "../components/home-contents/SignupForm";
import {useRef} from "react";
import {Team} from "../components/home-contents/Team";

const Home = () => {

    const signUpRef = useRef(null);

    return (
        <Box>
            <LogoHeader scrollRef={signUpRef}/>

            <Description/>

            <Mission/>

            <Team/>

            <SignupForm signUpRef={signUpRef} />
        </Box>
    );
}

export default Home;

import {Box, chakra, Heading, Text, HStack, VStack, Stack, Flex, Spacer} from "@chakra-ui/react";

export const Description = () => {
    return (
        <Box bg="white" pt={[20, 40]} pb={[14, 20]}>
            <Stack direction={["column", "column"]} spacing={[20, 20]}>

                <Stack spacing={[10, 2]} direction={["column", "row"]} align='center' px={[10, 20]}>

                    <Heading as='h1' color='black' fontSize={['28pt', '54pt']} fontWeight={900} w='100%'>
                        A Storyverse* Studio
                    </Heading>


                    <Box w='100%' pl={[0, 20]}>
                        <Text
                            color='black'
                            fontSize={['12pt', '22pt']}
                        >
                            A creator-led studio that crafts dynamic narratives that seamlessly blur the lines between
                            linear and interactive content.
                        </Text>
                    </Box>
                </Stack>

                <Box w={['100%', '50%']}>
                    <Text
                        color='black'
                        fontSize={['9pt', '10pt']}
                        fontWeight={100}
                        px={[10, 20]}
                    >
                        * storytelling across multiple platforms, where each platform makes a distinct contribution to a
                        unified story world
                    </Text>
                </Box>

            </Stack>
        </Box>
    );
}
import {Box, Text, Stack, Heading} from "@chakra-ui/react";

export const Mission = () => {
    return (
        <Box bg='black' py={[20, 40]}>

            <Stack direction={["column", "row"]} spacing={[10, 2]} align='center' px={[10, 20]}>

                <Heading as='h1' color='white' fontSize={['28pt', '54pt']} fontWeight={900} w='100%'>
                    Our Mission
                </Heading>

                <Text color='white' fontSize={['12pt', '22pt']}  pl={[0, 20]} w='100%'>
                    To redefine how stories are told, experienced, and shared across platforms,
                    empowering audiences to actively participate in the worlds they love. Welcome to the next
                    generation
                    of storytelling.
                </Text>

            </Stack>

        </Box>
    );
}

import {Box, Image, Center, chakra, Heading, Text, Link} from "@chakra-ui/react";
import { motion } from 'framer-motion'
import {isMobile} from 'react-device-detect'
import {useRef} from "react";

export const LogoHeader = ({scrollRef}) => {
    return (
        <Box
            bg='#F52549'
            w='vw'
            h='vh'
            position='relative'
        >
            <Center
                bg='#F52549'
            >
                <Image
                    src={isMobile ? 'images/logo_mobile.webp' : 'images/logo.webp'}
                    borderRadius={['50px', '150px']}
                    p={[5, 12]}
                />
            </Center>

            {/*<Box w='full' mt={['-65px', '-165px']}>*/}
            {/*    <Heading size={['sm', 'xl']} as='h1' color='white' textAlign='center' color='#839cff'>A STORYVERSE*/}
            {/*        <chakra.sup fontSize={['8pt', '24pt']}>*</chakra.sup>*/}
            {/*        &nbsp;STUDIO</Heading>*/}
            {/*</Box>*/}

            <Box
                as={motion.div}
                animate={{
                    scale: [1, 1.1, 1],
                    transition: {
                        duration: 2.5,
                        repeat: Infinity,
                        ease: "easeInOut"
                    }
                }}
                position='absolute'
                cursor='pointer'
                bg='yellow'
                w={['50px', '100px']}
                h={['50px', '100px']}
                rounded='full'
                right={[10, 20]}
                bottom={[8,20]}
                onClick={() => scrollRef.current.scrollIntoView()}
            >
                <Center h='100%'>
                    <Text textAlign='center' fontSize={[13, 16]} fontWeight={900} lineHeight={1}>SIGN UP</Text>
                </Center>
            </Box>
        </Box>
    );
}

import {Box, Text, VStack, Input, HStack, Button, Checkbox} from "@chakra-ui/react";
import {useState} from "react";

export const SignupForm = ({signUpRef}) => {

    const [email, setEmail] = useState("")
    const [subscribeChecked, setSubscribeChecked] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmitting(true)

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                setSubmitting(false)
                setSubmitted(true)
            })
            .catch((error) => console.log(error))
    };

    return (
        <Box bg='white' py={16} px={[6, 12]} ref={signUpRef}>
            <VStack gap={14} align='left'>
                <Text  textAlign={['left', 'left']} fontSize={['18pt', '46pt']} fontWeight={900} lineHeight={1.2}>
                    Sign up for sneak peeks and exclusive updates!
                </Text>

                <Box
                    name='sign-up'
                    as='form'
                    data-netlify="true"
                    onSubmit={handleSubmit}
                >
                    <Input
                        type='hidden'
                        name='form-name'
                        value='sign-up'
                    />

                    {!submitted ?
                        <HStack w='100%' gap={[8, 16]}>
                            <Input
                                variant='flushed'
                                placeholder='Email'
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                name="sign-up-email"
                                id='sign-up-email'
                                required={true}
                            />
                            <Button
                                type='submit'
                                bg='black'
                                textColor='white'
                                rounded='full'
                                size='lg'
                                w={['40%', '60%']}
                                _hover={{bg: 'blackAlpha.800'}}
                                isLoading={submitting}
                                isDisabled={!subscribeChecked}
                            >
                                Submit
                            </Button>
                        </HStack>
                        :
                        <Text textAlign='left' fontSize='12pt' fontWeight={400} lineHeight={1.2}>
                            Thank you - you have successfully subscribed to our newsletter!
                        </Text>}
                </Box>
                {!submitted ?
                    <Box mt='-30px'>
                        <Checkbox
                            onChange={(e) => setSubscribeChecked(e.target.checked)}
                        >
                            Yes, subscribe me to your newsletter
                        </Checkbox>
                    </Box> :
                    null
                }


                <Box>
                    <VStack gap={4} align='left'>
                        <Text textAlign='left' fontSize='12pt' fontWeight={400} lineHeight={1.2}>
                            We are actively looking for unique new ideas – <br/>
                            If you are a creator with a story to tell, get in touch!
                        </Text>

                        <Text textAlign='left' fontSize='12pt' fontWeight={400} cursor='pointer'
                              textDecoration='underline'>
                            creators@spoonful-studios.com
                        </Text>
                    </VStack>
                </Box>
            </VStack>
        </Box>
    )
}
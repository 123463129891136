import {Box, Text, Stack, Avatar, VStack, HStack, Center, Heading, Image, Flex} from "@chakra-ui/react";
import {SocialMediaLinks} from "../SocialMediaLinks";

export const Team = () => {
    return (

            <Box bg='#F52549'>

                <Stack direction={["column", "row"]} align='center' alignItems='stretch'>

                    <Heading as='h1' color='white' fontSize={['28pt', '54pt']} fontWeight={900} w={['65%', '100%']}
                             px={[10, 20]} py={[20, 60]}
                    >
                        The Team
                    </Heading>

                    <Flex alignItems='stretch' w='100%' h={['250px', 'auto']} mt={[-10, 0]}>
                        <Box flex='1' bgImage='images/mikko.webp' bgSize='cover' bgPos='center' position='relative'>
                            <Box w='100%' position='absolute' bottom={0} left={0} bg='blackAlpha.500'>
                                <VStack  spacing={0} align='left' px={[1, 4]} py={[1, 2]}>
                                    <Text fontWeight={700} fontSize={[11, 14]} color='white'>MIKKO MARTIKAINEN</Text>
                                    <Text fontWeight={700} fontSize={[11, 14]} color='white'>CEO</Text>
                                </VStack>
                            </Box>
                        </Box>
                        <Box flex='1' bgImage='images/laura.webp' bgSize='cover' bgPos='center' position='relative'>
                            <Box w='100%' position='absolute' bottom={0} left={0} bg='blackAlpha.500'>
                                <VStack  spacing={0} align='left' px={[1, 4]} py={[1, 2]}>
                                    <Text fontWeight={700} fontSize={[11, 14]} color='white'>LAURA CORTES</Text>
                                    <Text fontWeight={700} fontSize={[11, 14]} color='white'>CCO</Text>
                                </VStack>
                            </Box>
                        </Box>
                        {/*<Box flex='1' bgImage='images/tero.webp' bgSize='cover' bgPos='center' position='relative'>*/}
                        {/*    <Box w='100%' position='absolute' bottom={0} left={0} bg='blackAlpha.500'>*/}
                        {/*        <VStack  spacing={0} align='left' px={[1, 4]} py={[1, 2]}>*/}
                        {/*            <Text fontWeight={700} fontSize={[11, 14]} color='white'>TERO TAMMINEN</Text>*/}
                        {/*            <Text fontWeight={700} fontSize={[11, 14]} color='white'>Head of Content</Text>*/}
                        {/*        </VStack>*/}
                        {/*    </Box>*/}
                        {/*</Box>*/}
                    </Flex>

                </Stack>

            </Box>

    );
}

import {ButtonGroup, IconButton} from '@chakra-ui/react'
import * as React from 'react'
import {FaLinkedin, FaEnvelope} from 'react-icons/fa'

export const SocialMediaLinks = (props) => (
    <ButtonGroup variant="ghost" {...props} spacing='0'>
            <IconButton
                as="a"
                href={props.linkedIn}
                aria-label="LinkedIn"
                color="whiteAlpha.900"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaLinkedin />}
            />
            <IconButton
                as="a"
                href={props.email}
                aria-label="Email"
                color="whiteAlpha.900"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaEnvelope/>}
            />
    </ButtonGroup>
)


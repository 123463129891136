import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/montserrat'

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
    styles: {
        global: {
            html: {
                scrollBehavior: "smooth",
            },
        }
    },
    fonts: {
        heading: `'Montserrat', sans-serif`,
        body: `'Montserrat', sans-serif`,
    },
})

root.render(
  <React.StrictMode>
      <ChakraProvider theme={theme}>
    <App />
      </ChakraProvider>
  </React.StrictMode>
);

